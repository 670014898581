import { object } from 'prop-types'
import { Post } from '../utils/interfaces'
import dayjs from 'dayjs'
import Link from 'next/link'
import Image from 'next/image'

const PostCard = ({ post }: { post: Post }) => {
  return (
    <div className={'border rounded m-2'}>
      <div className={'flex flex-col'}>
        <Link href={`/post/${post.slug}`} passHref>
          <a className={'w-full h-48 mb-2 relative'}>
            <Image
              src={post.header.url}
              alt={post.title}
              layout={'fill'}
              className={'object-cover rounded-t'}
            />
          </a>
        </Link>
        <div>
          <a className={'m-2 text-sm text-gray-500'}>
            {dayjs(post.created).format('MMM DD, YYYY')}
          </a>
          <Link href={`/${post.category.name.toLowerCase()}`}>
            <a className={'m-2 text-sm text-yellow-600'}>
              {post.category.name}
            </a>
          </Link>
        </div>
        <Link href={`/post/${post.slug}`} passHref>
          <a className={'m-2 font-normal text-xl'}>{post.title}</a>
        </Link>
      </div>
    </div>
  )
}

PostCard.props = {
  post: object,
}

export default PostCard
