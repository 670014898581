import tw from 'twin.macro'
import { GetStaticProps } from 'next'
import apolloClient from '../utils/apollo'
import { gql } from '@apollo/client'
import PostCard from '../components/postcard'
import { Post } from '../utils/interfaces'

const Main = tw.div`
        pt-28
        xl:mx-56
`

const getStaticProps: GetStaticProps = async () => {
  const posts = await apolloClient.query({
    query: gql`
      query {
        blogPostCollection(limit: 5) {
          items {
            title
            created
            slug
            header {
              url
            }
            category {
              name
            }
          }
        }
      }
    `,
  })

  return {
    props: {
      posts: posts.data.blogPostCollection.items,
    },
    revalidate: 10,
  }
}

const Index = ({ posts }: { posts: Post[] }) => {
  return (
    <Main>
      <a className={'ml-2'}>Featured</a>
      {posts &&
        posts.map((post: Post, key: number) => {
          return <PostCard post={post} key={key} />
        })}
    </Main>
  )
}

export default Index

export { getStaticProps }
